import { navigate } from '@reach/router';
import bg_limit from 'assets/svg/bg-limit.svg';
import IconDollar from 'assets/svg/icons/dollar.inline.svg';
import IconFile from 'assets/svg/icons/file.inline.svg';
import IconLimit from 'assets/svg/limit-col-light.inline.svg';
import Button from 'components/Molecules/Button';
import { routes } from 'constants/routes';
import HeaderContext from 'providers/HeaderProvider';
import ThemeContext from 'providers/ThemeProvider';
import React, { useContext, useEffect } from 'react';
import { css } from 'twin.macro';

const bgLimit = css`
	max-height: 800px;
	height: 65vw;
	position: relative;
	background-image: url(${bg_limit});
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
`;

export default function offer() {
	const { setHeader } = useContext(HeaderContext);
	const { setPrimaryColor } = useContext(ThemeContext);

	useEffect(() => {
		setHeader({ title: 'Produkt Limit', isLimit: true });
		setPrimaryColor('#25848D');
	}, []);

	return (
		<div>
			<div css={bgLimit} className='flex flex-col items-center justify-center bg-select-offer w-full mb-15'>
				<IconLimit className='w-120 h-120' />
				<p className='font-semibold'>Faktoring krajowy</p>
			</div>

			<div className='flex flex-col items-center justify-center px-15 mb-10'>
				<div className='w-full'><p className='text-16 mt-05 mb-15 leading-15'>Dla kogo faktoring krajowy?</p></div>
				<p>Usługa Limit to oferta kierowana do przedsiębiorstw, których odbiorcami usług lub nabywcami produktów są firmy z siedzibą w Polsce. Długie terminy płatności i brakdostępu do pieniędzy zaburza Twoją płynność ? Dzięki Usłudze Limit dostaniesz środki od Smart Faktor od razu po zgłoszeniu wierzytelności do wykupu, Faktor przelewa pieniądze w ustalonej walucie na Twoje konto nawet tego samego dnia i cierpliwie czeka na zapłatę przedmiotowej faktury przez Twojego klienta w umówionym terminie.</p>

				<Button primary onClick={() => navigate(routes.LIMIT_FORM)} tw='bg-secondary mt-10 mb-30'>
					<IconDollar/><p>Sprawdź ofertę</p>
				</Button>

				<img src='../../../files/pic2b.svg' className="w-110 h-110" />
				<div className='w-full'><p className='text-16 w-17/20 mt-05 mb-15 leading-15'>Jakich klientów obsługujemy?</p></div>
				<p className='mb-30'>Nasz produkt kierujemy do wszystkich przedsiębiorców, niezależnie od formy prawnej w jakiej prowadzą swój biznes. Obsługujemy jednoosobowe działalności gospodarcze, spółki cywilne, jawne, spółki z o.o., spółki akcyjne oraz spółki komandytowe. Jeżeli masz dobrych i uznanych odbiorców bądź dobry kontrakt – nawet krótki czas prowadzenia działalności nie będzie przeszkodą.</p>

				<img src='../../../files/pic1a.svg' className="w-110 h-110" />
				<div className='w-full'><p className='text-16 w-17/20 mt-05 mb-15 leading-15'>Jak to działa?</p></div>
				<p>Na Twoją firmę przyznamy limit, nawet 500.000,00 zł – w ramach tego przyznamy też limity na Twoich kontrahentów. Przyjmiemy wszystkie faktury do górnej wysokości tych limitów. Limit jest odnawialny - co oznacza, iż jest do ponownego wykorzystania w dniu spłaty poprzednich wierzytelności. Wykorzystujesz ile chcesz i kiedy chcesz, Twój limit możesz traktować jak typowy stand-by lub overdraft.</p>

				<Button primary onClick={() => navigate(routes.WELCOME_LIMIT_ADVANCED_FORM)} tw='bg-secondary mt-10 mb-20'>
					<IconFile/><p>Złóż wniosek</p>
				</Button>

				<img src='../../../files/pic3a.svg' className="w-110 h-110" />
				<div className='w-full'><p className='text-16 w-17/20 mt-05 mb-15 leading-15'>Jakie są wysokości zaliczek płaconych za nabywane wierzytelności?</p></div>
				<p className='mb-30'>Jako Faktor płacimy zaliczkę za nabywane wierzytelności – wynosi ona od 35 % do 90 % wartości faktury. Wysokość zaliczki wpływa na cenę całej usługi – im niższa zaliczka, tym niższa cena. Pozostałą część faktury pomniejszoną o koszty naszych usług wypłacamy niezwłocznie po otrzymaniu płatności od Twojego kontrahenta. </p>

				<img src='../../../files/pic4a.svg' className="w-110 h-110" />
				<div className='w-full'><p className='text-16 w-17/20 mt-05 mb-15 leading-15'>Jakie terminy płatności akceptujemy?</p></div>
				<p>Jakie terminy płatności akceptujemy? Dobrze wiemy, że płynność to kluczowa sprawa w prowadzeniu biznesu. Wiemy też, że długi termin płatności to często ważny argument w negocjacjach handlowych a czasami wręcz konieczność. Wychodząc temu naprzeciw przyjmujemy do wykupu faktury z terminem płatności nawet do 180 dni.</p>

				<Button primary onClick={() => navigate(routes.LIMIT_FORM)} tw='bg-secondary mt-30 mb-15'>
					<IconDollar/><p>Sprawdź ofertę</p>
				</Button>
				<Button primary onClick={() => navigate(routes.WELCOME_LIMIT_ADVANCED_FORM)} tw='bg-secondary'>
					<IconFile/><p>Złóż wniosek</p>
				</Button>
			</div>
		</div>
	);
}
